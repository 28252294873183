export enum CodingType {
  script = 'script',
  notebook = 'notebook',
  generic = 'generic',
}

export const imageExtensions = ['jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'svg', 'svgz', 'png', 'gif', 'webp', 'apng', 'gif']

export const videoExtensions = ['mp4', 'ogg', 'webm']

export interface CodingLanguageRecord {
  type: CodingType
  extensions: string[]
  binary?: boolean
}

const internalCodingLanguage = {
  coq: [
    {
      type: CodingType.script,
      extensions: ['v', 'g'],
    },
  ],
  _coqproject: [
    {
      type: CodingType.script,
      extensions: [],
    },
  ],
  dune: [
    {
      type: CodingType.script,
      extensions: ['dune'],
    },
  ],
  'dune-project': [
    {
      type: CodingType.script,
      extensions: [],
    },
  ],
  opam: [
    {
      type: CodingType.script,
      extensions: ['opam'],
    },
  ],
  go: [
    {
      type: CodingType.script,
      extensions: ['go'],
    },
  ],
  'go.mod': [
    {
      type: CodingType.script,
      extensions: [],
    },
  ],
  'go.sum': [
    {
      type: CodingType.script,
      extensions: [],
    },
  ],
  solidity: [
    {
      type: CodingType.script,
      extensions: ['sol'],
    },
  ],
  haskell: [
    {
      type: CodingType.script,
      extensions: ['hs'],
    },
  ],
  'literal-haskell': [
    {
      type: CodingType.script,
      extensions: ['lhs'],
    },
  ],
  cabal: [
    {
      type: CodingType.script,
      extensions: ['cabal'],
    },
  ],
  k: [
    {
      type: CodingType.script,
      extensions: ['k'],
    },
  ],
  plaintext: [
    {
      type: CodingType.script,
      extensions: ['txt'],
    },
  ],
  image: [
    {
      type: CodingType.generic,
      extensions: imageExtensions,
      binary: true,
    },
  ],
  video: [
    {
      type: CodingType.generic,
      extensions: videoExtensions,
      binary: true,
    },
  ],
  pdf: [
    {
      type: CodingType.generic,
      extensions: ['pdf'],
      binary: true,
    },
  ],
  css: [
    {
      type: CodingType.script,
      extensions: ['css'],
    },
  ],
  html: [
    {
      type: CodingType.script,
      extensions: ['html', 'htm', 'shtml', 'xhtml', 'mdoc', 'jsp', 'asp', 'aspx', 'jshtm'],
    },
  ],
  ini: [
    {
      type: CodingType.script,
      extensions: ['ini', 'properties', 'gitconfig'],
    },
  ],
  java: [
    {
      type: CodingType.script,
      extensions: ['java', 'jav'],
    },
  ],
  javascript: [
    {
      type: CodingType.script,
      extensions: ['js', 'es6', 'jsx', 'mjs'],
    },
  ],
  json: [
    {
      type: CodingType.script,
      extensions: ['json', 'bowerrc', 'jshintrc', 'jscsrc', 'eslintrc', 'babelrc', 'har'],
    },
  ],
  less: [
    {
      type: CodingType.script,
      extensions: ['less'],
    },
  ],
  markdown: [
    {
      type: CodingType.script,
      extensions: ['md', 'markdown', 'mdown', 'mkdn', 'mkd', 'mdwn', 'mdtxt', 'mdtext'],
    },
  ],
  php: [
    {
      type: CodingType.script,
      extensions: ['php', 'php4', 'php5', 'phtml', 'ctp'],
    },
  ],
  python: [
    {
      type: CodingType.script,
      extensions: ['py', 'rpy', 'pyw', 'cpy', 'gyp', 'gypi'],
    },
  ],
  rust: [
    {
      type: CodingType.script,
      extensions: ['rs'],
    },
  ],
  jupyter: [
    {
      type: CodingType.notebook,
      extensions: ['ipynb'],
    },
  ],
  isle: [
    {
      type: CodingType.generic,
      extensions: ['misl'],
    },
  ],
  canvas: [
    {
      type: CodingType.notebook,
      extensions: ['mpsw'],
    },
  ],
  strategy: [
    {
      type: CodingType.notebook,
      extensions: ['strat'],
    },
  ],
  portfolio: [
    {
      type: CodingType.notebook,
      extensions: ['mpsf'],
    },
  ],
  pasa: [
    {
      type: CodingType.notebook,
      extensions: ['pasa'],
    },
  ],
  agent: [
    {
      type: CodingType.notebook,
      extensions: ['kagent'],
    },
  ],
  pipeline: [
    {
      type: CodingType.notebook,
      extensions: ['pass'],
    },
  ],
  pipelineAgent: [
    {
      type: CodingType.notebook,
      extensions: ['passage'],
    },
  ],
  chat: [
    {
      type: CodingType.notebook,
      extensions: ['kchat'],
    },
  ],
  scheme: [
    {
      type: CodingType.script,
      extensions: ['scm', 'ss', 'sch', 'rkt'],
    },
  ],
  scss: [
    {
      type: CodingType.script,
      extensions: ['scss'],
    },
  ],
  twig: [
    {
      type: CodingType.script,
      extensions: ['twig'],
    },
  ],
  typescript: [
    {
      type: CodingType.script,
      extensions: ['ts', 'tsx'],
    },
  ],
  xml: [
    {
      type: CodingType.script,
      extensions: ['xml', 'dtd', 'ascx', 'csproj', 'config', 'wxi', 'wxl', 'wxs', 'xaml', 'svg', 'svgz', 'opf', 'xsl'],
    },
  ],
  yaml: [
    {
      type: CodingType.script,
      extensions: ['yaml', 'yml', 'lock'],
    },
  ],
  makefile: [
    {
      type: CodingType.script,
      extensions: [],
    },
  ],
  dockerfile: [
    {
      type: CodingType.script,
      extensions: [],
    },
  ],
  sql: [
    {
      type: CodingType.script,
      extensions: ['sql'],
    },
  ],
  excel: [
    {
      type: CodingType.generic,
      extensions: ['xls', 'xlsx'],
      binary: true,
    },
  ],
  csv: [
    {
      type: CodingType.generic,
      extensions: ['csv'],
    },
  ],
  ods: [
    {
      type: CodingType.generic,
      extensions: ['ods'],
      binary: true,
    },
  ],
  parquet: [
    {
      type: CodingType.generic,
      extensions: ['parquet'],
      binary: true,
    },
  ],
}

// this will validate the typing on the content of 'internalCodingLanguage', as well as export a sanitized version of it (with types)
// while the actual object keys will be used for CodingLanguage.
// the only downside of this structure is that any type errors will be displayed on this line instead of where they are.
export const codingLanguage: Record<CodingLanguage, Array<CodingLanguageRecord>> = internalCodingLanguage

export type CodingLanguage = keyof typeof internalCodingLanguage
