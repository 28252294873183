import { generatePath, Params } from 'react-router-dom'
import type { PublicationCategory } from '~api'
import type Exact from '~utils/exact'
import type {
  AdminSearchParams,
  EmbedParams,
  EmbedSearchParams,
  GlobalSearchParams,
  LegacyEmbedParams,
  PortfolioDetailsParams,
  ProjectEditSearchParams,
  PublicationSearchParams,
  SearchParam,
  SearchParams,
  TokenDetailsParams,
} from './searchParams'

export class Path<T extends Params, S extends SearchParams = GlobalSearchParams> {
  public readonly pattern: string

  constructor(pattern: string) {
    this.pattern = pattern
  }

  format<RT extends T, RS extends S>(params?: Exact<T, RT>, searchParams?: Exact<S, RS>) {
    const searchParamsAsString: Array<[string, string]> = []

    if (searchParams) {
      Object.getOwnPropertyNames(searchParams).forEach(k => {
        let v = (searchParams[k] as SearchParam | Array<SearchParam>) || []

        if (!Array.isArray(v)) {
          v = [v]
        }

        v.filter(x => x !== undefined)
          .map(x => x?.toString() || '')
          .forEach(value => {
            searchParamsAsString.push([k, value])
          })
      })
    }

    const urlSearchParams = new URLSearchParams(searchParamsAsString)
    const urlSearchParamsAsString = urlSearchParams.toString()

    const path = generatePath(this.pattern, params)

    if (urlSearchParamsAsString) {
      return `${path}?${urlSearchParamsAsString}`
    }

    return path
  }

  formatAsAbsoluteURL<RT extends T, RS extends S>(params?: Exact<T, RT>, searchParams?: Exact<S, RS>) {
    const path = this.format(params, searchParams)

    return `${window.location.origin}${path}`
  }
}

export type AdminNestedPath =
  | 'newsroom'
  | 'scheduled-jobs'
  | 'activity'
  | 'users'
  | 'roles'
  | 'referrals'
  | 'rate-plans'
  | 'affiliates'
  | 'trading-bot-channels'
  | 'documentation'
  | 'jupyter_usages'
  | 'watermark'

export enum PublicProfileTab {
  published = 'published',
  projects = 'projects',
  followers = 'followers',
  following = 'following',
  about = 'about',
  teams = 'teams',
}
export enum PublicationsNestedPath {
  isles = 'isles',
  canvases = 'canvases',
  notebooks = 'notebooks',
  pasas = 'pasas',
  chats = 'chats',
  agents = 'agents',
  pipelineAgents = 'pipelineAgents',
}
type ProfileEditTabs = 'General' | 'Secrets' | 'Preferences' | 'Pricing' | 'Usage' | 'Integrations' | 'Processes' | 'APIs'

const Paths = {
  root: new Path('/'),

  gitHubOAuthCallback: new Path('/auth/github'),

  embedCanvas: new Path<{ embedId: string }, EmbedSearchParams>('/embed/canvas/:embedId'),
  embed: new Path<EmbedParams, EmbedSearchParams>('/embed/:type/:projectId/:fileId/:entityId'),
  legacyEmbed: new Path<LegacyEmbedParams, EmbedSearchParams>('/embed/:projectId/:fileId/:entityId'),

  printCanvas: new Path<{ projectId: string; fileId: string }, { versionId?: string }>('/print-canvas/:projectId/:fileId'),

  dashboard: new Path('/dashboard'),
  profileEdit: new Path<{}, { tab?: ProfileEditTabs }>('/profile'),
  notifications: new Path<{ '*'?: string }>('/notifications/*'),

  automation: new Path('/automation'),

  publicProfile: new Path<{ username: string }>('/users/:username'),
  publicProfileWithTab: new Path<{ username: string; tab: PublicProfileTab }>('/users/:username/:tab'),

  gitHub: new Path('/github'),

  wallet: new Path('/wallet'),

  legacyResourcePortal: new Path('/resources/*'),
  legacyResourcePortalVideos: new Path('/videos/*'),

  teams: new Path('/teams'),
  teamDetails: new Path<{ id: string }>('/teams/:id/*'),

  portfolios: new Path<{}, { from?: 'index' }>('/portfolios'),
  portfolioDetails: new Path<PortfolioDetailsParams>('/portfolios/:id'),

  tokenDetails: new Path<TokenDetailsParams>('/tokens/:id'),

  projectsGitHubAutoImportWithBranch: new Path<{ gitUser: string; gitRepo: string; gitBranch: string }>(
    '/projects/github/:gitUser/:gitRepo/:gitBranch',
  ),
  projectsGitHubAutoImport: new Path<{ gitUser: string; gitRepo: string }>('/projects/github/:gitUser/:gitRepo'),
  projectsImport: new Path<{ provider: string }>('/projects/import/:provider'),
  projects: new Path('/projects'),

  projectEditHack: new Path<{ hackProjectName: 'vlsm' }>('/projects/:hackProjectName(vlsm)'),
  projectEdit: new Path<{ id: string }, ProjectEditSearchParams>('/projects/:id'),
  projectEditWithUsername: new Path<{ username: string; id: string }, ProjectEditSearchParams>('/:username/projects/:id'),
  legacyNotebookEditWithUsername: new Path<{ username: string; id: string }, ProjectEditSearchParams>('/:username/notebooks/:id'),
  legacyNotebooks: new Path<{ '*'?: string }>('/notebooks/*'),

  reports: new Path('/reports'),
  reportOfIsleDetails: new Path<{ slug: string; '*'?: string }>(`/reports/${PublicationsNestedPath.isles}/:slug/*`),
  reportOfPasaDetails: new Path<{ slug: string }>(`/reports/${PublicationsNestedPath.pasas}/:slug`),
  reportOfNotebookDetails: new Path<{ slug: string }, PublicationSearchParams>(`/reports/${PublicationsNestedPath.notebooks}/:slug`),
  reportOfCanvasDetails: new Path<{ slug: string }>(`/reports/${PublicationsNestedPath.canvases}/:slug`),

  publications: new Path('/publications'),
  publicationsWithCategory: new Path<{ category: PublicationCategory }>('/publications/category/:category'),
  publicationsWithCategoryAndType: new Path<{ category: PublicationCategory; type: PublicationsNestedPath }>(
    '/publications/category/:category/:type',
  ),
  publicationsWithType: new Path<{ type: PublicationsNestedPath }>('/publications/:type'),
  publishedIsleDetails: new Path<{ slug: string; '*'?: string }>(`/publications/${PublicationsNestedPath.isles}/:slug/*`),
  publishedPasaDetails: new Path<{ slug: string }>(`/publications/${PublicationsNestedPath.pasas}/:slug`),
  publishedNotebookDetails: new Path<{ slug: string }, PublicationSearchParams>(`/publications/${PublicationsNestedPath.notebooks}/:slug`),
  publishedCanvasDetails: new Path<{ slug: string }>(`/publications/${PublicationsNestedPath.canvases}/:slug`),
  publishedAgentDetails: new Path<{ slug: string }, PublicationSearchParams>(`/publications/${PublicationsNestedPath.agents}/:slug`),
  publishedPipelineAgentDetails: new Path<{ slug: string }, PublicationSearchParams>(`/publications/${PublicationsNestedPath.pipelineAgents}/:slug`),

  strategies: new Path('/strategies'),
  strategyDetails: new Path<{ slug: string }>(`/strategies/:slug`),

  legacyFeatured: new Path('/featured'),
  legacyFeaturedDetails: new Path<{ slug: string }>('/featured/:slug'),

  exchanges: new Path('/exchanges'),
  connectExchange: new Path<{ id: string }>('/exchanges/:id/connect'),

  newsroom: new Path('/newsroom'),

  admin: new Path<{ '*'?: AdminNestedPath }, AdminSearchParams>('/admin/*'),

  themePreview: new Path('/theme-preview'),

  slackOAuthCallback: new Path('/oauth/slack/callback'),

  documentationRoot: new Path('/documentation'),
  documentation: new Path<{ slug: string; '*'?: string }>('/documentation/:slug/*'),

  referralsDashboard: new Path('/referrals/dashboard'),
}

export default Paths
