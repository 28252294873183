import { keysOf } from '@unbounded/unbounded-components'
import { File, FileEntity, FileEntityType, VirtualFileEntityType } from '~data/file/file-type'
import { CodingLanguage, CodingLanguageRecord, CodingType, codingLanguage } from '../type'

/**
 * Sorts the nodes alphabetically
 */
export function sortNodes(nodes: Array<FileEntity>) {
  return nodes.sort((a, b) => {
    let sortValue = 0

    if (a.virtual === VirtualFileEntityType.edit) {
      sortValue -= 100
    }
    if (b.virtual === VirtualFileEntityType.edit) {
      sortValue += 100
    }
    if (a.type === FileEntityType.directory) {
      sortValue -= 10
    }
    if (b.type === FileEntityType.directory) {
      sortValue += 10
    }
    const aPath = a.name?.toLowerCase() || ''
    const bPath = b.name?.toLowerCase() || ''

    sortValue += aPath.localeCompare(bPath)
    return sortValue
  })
}

export function sortNodesWithLoadedDirectories(nodes: FileEntity[], rootNodeId?: string): FileEntity[] {
  // Group the files inside directories
  const filesInDirs = nodes.reduce(
    (acc, n) => {
      if (n.parentId) {
        acc[n.parentId] = [...(acc[n.parentId] || []), n]
      }

      return acc
    },
    {} as Record<string, FileEntity[]>,
  )

  // Files and directories in the root, sorted
  const rootFilesAndDirs = nodes.filter(n => n.parentId === rootNodeId)
  const sortedRootFilesAndDirs = sortNodes(rootFilesAndDirs)

  // Replace the directories with the files they contains.
  // The files are sorted recursively.
  const result = sortedRootFilesAndDirs.flatMap(f => {
    if (f.type === FileEntityType.file) return [f]

    if (filesInDirs[f.id]) {
      return sortNodesWithLoadedDirectories(filesInDirs[f.id], f.id)
    }

    return []
  })

  return result
}

export function sortFiles(files: File[]) {
  return files.sort((a, b) => {
    const aPath = a.name?.toLowerCase() || ''
    const bPath = b.name?.toLowerCase() || ''

    return aPath.localeCompare(bPath)
  })
}

/**
 * Gets file extension by file name
 */
export function getFileExtensionByName(fileName: string) {
  const name = fileName.toLowerCase().trim()

  return name.split('.').pop() || name
}

/**
 * Gets file first available extension by file language
 */
export function getFileExtensionByLanguage(language: CodingLanguage) {
  return codingLanguage[language]?.[0].extensions[0] || ''
}

/**
 * Gets file extension by file language
 */
export function getFileExtensionsByLanguage(language: CodingLanguage) {
  return codingLanguage[language]?.[0].extensions || []
}

/**
 * Gets file type by file language
 */
export function getFileTypeByLanguage(language: CodingLanguage) {
  return codingLanguage[language]?.[0].type || CodingType.script
}

export function getFileCodingLanguageRecord(fileName: string): [CodingLanguage, CodingLanguageRecord] | undefined {
  const lowerCaseFileName = fileName.toLowerCase()
  const extensionName = getFileExtensionByName(fileName)

  return keysOf(codingLanguage).reduce<[CodingLanguage, CodingLanguageRecord] | undefined>((acc, langId) => {
    const found = codingLanguage[langId].find((lang: CodingLanguageRecord) => {
      if (lowerCaseFileName === langId && !lang.extensions.length) {
        return true
      }

      return lang.extensions.includes(extensionName)
    })

    return found ? [langId as CodingLanguage, found] : acc
  }, undefined)
}

/**
 * Tries to get file coding language from name (extension)
 */
export function getFileCodingLanguage(fileName: string): CodingLanguage {
  return getFileCodingLanguageRecord(fileName)?.[0] || 'plaintext'
}

/**
 * Tries go get script type from name (extension)
 */
export function getFileType(fileName: string): CodingType {
  return getFileCodingLanguageRecord(fileName)?.[1].type || CodingType.script
}
