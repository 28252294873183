/* eslint-disable */
// TODO should be refactored if needed
import { capitalize } from '@unbounded/unbounded-components'

type KeyOrNull = string | null

export let API_KEY: KeyOrNull = null
export let TOKEN: KeyOrNull = null

export function setApiKey(key: KeyOrNull) {
  API_KEY = key
}

export function setToken(token: KeyOrNull) {
  TOKEN = token
}

class XmlHttpPromise<T> extends Promise<T> {
  promise: Promise<T>

  xhr: XMLHttpRequest

  headers: { [any: string]: string }

  method: string

  url: string

  body: any

  constructor(
    callback: (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void,
    xhr: XMLHttpRequest,
    headers: { [any: string]: string },
    method: string,
    url: string,
    body: any,
  ) {
    super(() => {})
    this.promise = new Promise(callback)
    this.xhr = xhr
    this.headers = headers
    this.method = method
    this.url = url
    this.body = body
  }

  on(event: string, callback: EventListenerOrEventListenerObject) {
    this.xhr.addEventListener(event, callback)
    return this
  }

  onUpload(event: string, callback: EventListenerOrEventListenerObject) {
    this.xhr.upload.addEventListener(event, callback)
    return this
  }

  then<TResult1, TResult2>(
    onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null,
    onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null,
  ): Promise<TResult1 | TResult2> {
    // do XHR
    this.xhr.responseType = 'blob'
    this.xhr.open(this.method, this.url, true)
    Object.getOwnPropertyNames(this.headers).forEach(k => {
      this.xhr.setRequestHeader(k, this.headers[k])
    })
    this.xhr.send(this.body)
    return this.promise.then(onfulfilled, onrejected)
  }

  catch(e: any) {
    return this.promise.catch(e)
  }
}

const createXmlHttpReadableStream = (bytes: any): ReadableStream<Uint8Array> =>
  new ReadableStream({
    start(controller) {
      controller.enqueue(bytes)
      controller.close()
    },
  })

const readBlobAsJson = <T>(blob: Blob): Promise<T> =>
  new Promise<T>(resolve => {
    const fileReader = new FileReader()

    fileReader.onload = function (event) {
      const jsonString = event.target?.result as string
      resolve(JSON.parse(jsonString) as T)
    }

    fileReader.readAsText(blob)
  })

const readBlobAsArrayBuffer = (blob: Blob): Promise<ArrayBuffer> =>
  new Promise(resolve => {
    const fileReader = new FileReader()

    fileReader.onload = function (event) {
      const arrayBuffer = event.target?.result as ArrayBuffer
      resolve(arrayBuffer)
    }

    fileReader.readAsArrayBuffer(blob)
  })

export interface PartialResponse {
  readonly ok: boolean
  readonly status: number
  readonly body: ReadableStream<Uint8Array> | null
  readonly headers: Headers
  json(): Promise<any>
  text(): Promise<string>
  blob(): Promise<Blob>
  arrayBuffer(): Promise<ArrayBuffer>
}

export type HTTPMethods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'HEAD' | 'DELETE'

export interface AuthorizedRequestParams extends RequestInit {
  noAuth?: boolean
  apiKey?: string
  token?: string
  sendJson?: boolean
  headers?: Record<string, string>
  backend?: 'xhr' | 'fetch'
  withProgress?: boolean
  onProgress?: (progress: { percent: number; loaded: number; total: number }) => void
  body?: any // change from regular fetch: we allow any JSON here
  method?: HTTPMethods
}

const xmlHttpFetch = (url: string, props: AuthorizedRequestParams): Promise<PartialResponse> => {
  const xhr = new XMLHttpRequest()

  return new XmlHttpPromise<PartialResponse>(
    (resolve, reject) => {
      try {
        xhr.addEventListener('load', () => {
          if (xhr.readyState === 4) {
            const PseudoFetchResult: PartialResponse = {
              text: () => new Promise(resolve => resolve(xhr.responseText)),
              json: () =>
                new Promise((resolve, reject) => {
                  try {
                    if (xhr.responseType === 'json' && xhr.response) {
                      resolve(xhr.response)
                    } else if (xhr.responseType === '' || xhr.responseType === 'text') {
                      resolve(JSON.parse(xhr.responseText))
                    } else {
                      resolve(readBlobAsJson(xhr.response))
                    }
                  } catch (e) {
                    reject(e)
                  }
                }),
              blob: () => new Promise(resolve => resolve(xhr.response)),
              arrayBuffer: () => readBlobAsArrayBuffer(xhr.response),
              body: createXmlHttpReadableStream(xhr.response),
              ok: xhr.status >= 200 && xhr.status <= 299,
              status: xhr.status,
              headers: new Headers(),
            }

            resolve(PseudoFetchResult)
          }
        })

        xhr.addEventListener('error', e => {
          reject(e)
        })

        xhr.addEventListener('abort', () => {
          reject(new APIError('Network request aborted', 0, APIErrorCode.abort))
        })

        if (props.withProgress !== false && props.onProgress) {
          xhr.upload.addEventListener('progress', event => {
            if (event.lengthComputable && props.onProgress) {
              const percent = (event.loaded / event.total) * 100
              props.onProgress({ percent, loaded: event.loaded, total: event.total })
            }
          })
        }

        if (props.signal) {
          props.signal.onabort = () => {
            xhr.abort()
          }
        }
      } catch (e) {
        reject(e)
      }
    },
    xhr,
    props?.headers || {},
    props?.method || 'GET',
    url,
    props?.body || null,
  )
}

export enum APIErrorCode {
  abort = 'abort',
  unknownHttpError = 'unknown-http-error',

  badRequest = '400',
  unauthorized = '401',
  forbidden = '403',
  notFound = '404',
  methodNotAllowed = '405',
  notAcceptable = '406',
  proxyAuthenticationRequired = '407',
  requestTimeout = '408',
  conflict = '409',
  gone = '410',
  lengthRequired = '411',
  preconditionFailed = '412',
  payloadTooLarge = '413',
  uriTooLong = '414',
  unsupportedMediaType = '415',
  rangeNotSatisfiable = '416',
  expectationFailed = '417',
  imATeapot = '418',
  misdirectedRequest = '421',
  upgradeRequired = '426',
  preconditionRequired = '428',
  tooManyRequests = '429',
  requestHeaderFieldsTooLarge = '431',
  unavailableForLegalReasons = '451',

  internalServerError = '500',
  notImplemented = '501',
  badGateway = '502',
  serviceUnavailable = '503',
  gatewayTimeout = '504',
  httpVersionNotSupported = '505',
  variantAlsoNegotiates = '506',
  notExtended = '510',
  networkAuthenticationRequired = '511',
}

const errorStatusToCode = (status = 0) => {
  const [, code] = Object.entries(APIErrorCode).find(([, statusString]) => status.toString() === statusString) || [
    'unknown-http-error',
    APIErrorCode.unknownHttpError,
  ]

  return code
}

interface AuthorizedFetchAPIError {
  error: string
  status?: number
  fields?: Record<string, string>
  details?: any
  exception?: any
  code?: APIErrorCode
}

class APIError extends Error implements AuthorizedFetchAPIError {
  error: string

  fields?: Record<string, string>

  details?: any

  exception?: Error

  status?: number

  code?: APIErrorCode

  constructor(message: string, status?: number, code?: APIErrorCode, fields?: Record<string, string>, details?: any, exception?: Error) {
    super(message)
    Object.setPrototypeOf(this, APIError.prototype)
    this.error = message
    this.status = status
    this.code = code || errorStatusToCode(status)
    this.fields = fields
    this.details = details
    this.exception = exception
  }
}

export function isRequestAborted(err: any) {
  if (!err) return false
  const isAPIAbort = Object.hasOwn(err, 'code') && err?.code === APIErrorCode.abort
  return isAPIAbort || err.name === 'AbortError'
}

export function isRequestNotFound(err: any) {
  if (!err) return false
  return Object.hasOwn(err, 'code') && err?.code === APIErrorCode.notFound
}

export function isRequestErrorCode(err: any, codeOrCodes: APIErrorCode | APIErrorCode[]): err is APIError {
  return Array.isArray(codeOrCodes) ? codeOrCodes.includes(err?.code as any) : err?.code === codeOrCodes
}

export function isRequestFetchError(err: any): err is APIError {
  return typeof err.code === 'string' && Object.values(APIErrorCode).includes(err.code)
}

const readErrorFromBody = (erroneousResponse: Response | PartialResponse): Promise<AuthorizedFetchAPIError | null> => {
  return new Promise(resolve => {
    if (!erroneousResponse.body) {
      resolve(null)
      return
    }

    const reader = erroneousResponse.body.getReader()
    const decoder = new TextDecoder()
    let responseText = ''

    const readAll = async (): Promise<string> => {
      const { value, done } = await reader.read()

      if (done) {
        return responseText
      }

      if (value instanceof Blob) {
        responseText += await value.text()
      } else if (value instanceof Uint8Array) {
        responseText += decoder.decode(value, { stream: true })
      }

      return readAll()
    }

    readAll().then(text => {
      try {
        const json: Record<string, any> = text ? JSON.parse(text) || {} : {}
        const fields = json.fields || null
        const details = json.details || null

        let errorMessage: string | undefined
        if (typeof json.error === 'string') {
          errorMessage = json.error
        } else if (Array.isArray(json.error)) {
          errorMessage = json.error.join(', ')
        }

        resolve(errorMessage ? { error: capitalize(errorMessage), fields, details, exception: null } : null)
      } catch (error) {
        resolve(null)
      }
    })
  })
}

async function doFetch(url: string, props?: AuthorizedRequestParams): Promise<Response | PartialResponse> {
  const headers = (props && props.headers) || {}

  if (!props) {
    props = {}
  } else {
    props = { ...props }
  }
  if (!props.noAuth) {
    if (props.apiKey) {
      headers['X-UN-API-Key'] = props.apiKey
    } else if (props.token) {
      headers['X-Authorization'] = `Bearer ${props.token}`
    } else if (API_KEY) {
      headers['X-UN-API-Key'] = API_KEY
    } else if (TOKEN) {
      headers['X-Authorization'] = `Bearer ${TOKEN}`
    }
  }
  if (props.sendJson) {
    headers['Content-Type'] = 'application/json'
    if (typeof props.body !== 'string') {
      props.body = JSON.stringify(props.body)
    }
  }
  props.headers = headers

  if (props && props.backend === 'xhr') {
    return xmlHttpFetch(url, props)
  }

  return fetch(url, props)
}

export async function authorizedFetch(url: string, props?: AuthorizedRequestParams): Promise<Response | PartialResponse> {
  try {
    const resp = await doFetch(url, props)

    // Convert non-2xx responses into exceptions
    if (!resp.ok) {
      const err = await readErrorFromBody(resp)
      const error = err ? err.error : `Error reading from response body, status: ${resp.status}`
      const fields = err ? err.fields : undefined
      const details = err ? err.details : undefined

      // Error `code` will be defined by status in constructor
      throw new APIError(error, resp.status, undefined, fields, details)
    }

    return resp
  } catch (err) {
    // Here's the place to handle generic error, like aborted or network errors.
    // We don't have to handle all of them, only ones we're interested in.

    if (err instanceof DOMException && err.name === 'AbortError') {
      throw new APIError(`${err.name}: ${err.message}`, 0, APIErrorCode.abort)
    }

    throw err
  }
}

export async function authorizedTextFetch(url: string, props?: AuthorizedRequestParams): Promise<string> {
  const resp = await authorizedFetch(url, props)
  return await resp.text()
}

export async function authorizedJsonFetch(url: string, props?: AuthorizedRequestParams): Promise<any> {
  const resp = await authorizedFetch(url, props)
  return await resp.json()
}
