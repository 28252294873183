import { matchPath } from 'react-router-dom'
import Paths from '~routes/paths'

const LS_KEY = 'mps-user-source'

type UserSource = 'git_import'

export function getUserSource() {
  return window.localStorage.getItem(LS_KEY) as UserSource | null
}

export function setUserSource(source: UserSource) {
  window.localStorage.setItem(LS_KEY, source)
}

export function initializeUserSource() {
  const isProjectImportPath = matchPath(Paths.projectsImport.pattern, window.location.pathname)
  if (isProjectImportPath) {
    setUserSource('git_import')
  }
}
