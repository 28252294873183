import { FileType } from '~types'

export const UPLOAD_MAX_FILE_SIZE_MB = 100
export const UPLOAD_MAX_FILE_SIZE = UPLOAD_MAX_FILE_SIZE_MB * 1024 * 1024

export const UPLOAD_THUMBNAIL_MAX_SIZE_MB = 5
export const UPLOAD_THUMBNAIL_MAX_SIZE = UPLOAD_THUMBNAIL_MAX_SIZE_MB * 1024 * 1024

export const UPLOAD_ALLOWED_FILE_TYPES = ['application', 'text', 'image', 'video']
export const UPLOAD_MAX_UPLOADS = 1000
export const UPLOAD_MAX_CONCURRENT_UPLOADS = 25

export const UPLOAD_MIME_TYPES: Record<FileType, string> = {
  [FileType.CSV]: 'text/csv',
  [FileType.JSON]: 'application/json',
  [FileType.JPEG]: 'image/jpeg',
  [FileType.JPG]: 'image/jpeg',
  [FileType.PNG]: 'image/png',
  [FileType.SVG]: 'image/svg+xml',
  [FileType.IPYNB]: 'application/x-ipynb+json',
  [FileType.Any]: '*',
}

export const UPLOAD_MIME_EXTENSIONS: Record<FileType, string[]> = {
  [FileType.CSV]: ['.csv'],
  [FileType.JSON]: ['.json'],
  [FileType.JPEG]: ['.jpeg', '.jpg'],
  [FileType.JPG]: ['.jpeg', '.jpg'],
  [FileType.PNG]: ['.png'],
  [FileType.SVG]: ['.svg'],
  [FileType.IPYNB]: ['.ipynb'],
  [FileType.Any]: ['*'],
}

export const UPLOAD_DEFAULT_ACCEPTED_FILES = {
  [UPLOAD_MIME_TYPES.csv]: UPLOAD_MIME_EXTENSIONS.csv,
  [UPLOAD_MIME_TYPES.json]: UPLOAD_MIME_EXTENSIONS.json,
}

export const UPLOAD_ANY_ACCEPTED_FILES = {
  [UPLOAD_MIME_TYPES['*']]: UPLOAD_MIME_EXTENSIONS['*'],
}

export const UPLOAD_THUMBNAIL_DEFAULT_ACCEPTED_FILES = [FileType.PNG, FileType.JPEG, FileType.JPG]
